import React from 'react';
import './resources/scss/app.scss'
//import 'bootstrap/dist/css/bootstrap.css'

import Home from './components/Content/Home/Home';

function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
